jQuery(document).ready(function ($) {
    $(document).on('click','.navbar-toggle',function(e) {
        $(this).toggleClass('collapsed');
    });

    $(document).on('click input', 'input[type="date"], input[type="text"].date-picker', function (e) {
        var $this = $(this);
        $this.prop('type', 'text').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            showButtonPanel: true,
            changeMonth: true,
            changeYear: true,
            dateFormat: 'd-m-yy',
            showWeek: true,
            firstDay: 1
        });

        setTimeout(function() {
            $this.datepicker('show');
        }, 1);
    });

    //Lock Check
    if($('.edit-page').length > 0){
        //get the type
        var type = $('.edit-page').attr('id');
        //get the id of the lock
        var lock_id = $('.edit-page').attr('data-id');

        //get the user id
        var user_id = $('#user_id').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name=_token]').val()
            }
        })

        var my_url = '/lockchecker';

        $.ajax({

            type: 'POST',
            url: my_url,
            data: {lock_type: type, lock_id: lock_id, user_id: user_id},
            dataType: 'json',
            success: function (data) {
                if(data.user_id){
                    if(data.user_id != user_id){
                        $('.action-bar').after('<div class="lock-warning">Please note that '+data.user_name+' is already editing this section.</div>');
                        $('.btn-primary').hide();
                    }
                }
            },
        });
        $(window).bind('beforeunload', function(){
            //get the type
            var type = $('.edit-page').attr('id');
            //get the id of the lock
            var lock_id = $('.edit-page').attr('data-id');

            //get the user id
            var user_id = $('#user_id').val();

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('input[name=_token]').val()
                }
            })

            var my_url = '/lockcheckerdelete';

            $.ajax({

                type: 'POST',
                url: my_url,
                data: {lock_type: type, lock_id: lock_id, user_id: user_id},
                dataType: 'json',
                success: function (data) {

                },
            });        });
    }



    var url = "/message";

    //factor[insurance_included]

    $(".factor input").on("click", function () {
        if ($(this).is(':checked')) {
            $('.insurance-section').show();
        } else {
            $('.insurance-section').hide();
        }
    });

    $("#propertySale\\[sale_type\\]").on("change", function () {
        if ($(this).val() == '1') { //private sale
            $('.on-market').hide();
            $('.private-sale').show();
        } else if ($(this).val() == '2') {
            $('.on-market').show();
            $('.private-sale').hide();
        } else {
            $('.on-market').hide();
            $('.private-sale').hide();
        }
    });

    $('.panel-title a').on("click", function (e) {
        $(this).children('i').toggleClass('glyphicon-circle-arrow-down glyphicon-circle-arrow-up');
    });

    //delete task and remove it from list
    $('.delete-msg').on("click", function (e) {
        e.preventDefault();
        msg_id = this.id;
        var r = confirm("Please confirm if you want to delete this message.");
        if (r == true) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('input[name=_token]').val()
                }
            })

            $.ajax({
                type: "DELETE",
                url: '/message/' + msg_id,
                success: function (data) {
                    $('#row-' + msg_id).hide();
//                $("#task" + task_id).remove();
                },
                error: function (data) {
                    console.log('Error:', data);
                }
            });
        }

    });

    $("#type_of_referral").change(function (e) {
        if($('#type_of_referral :selected').val() == 'openMarket' || $('#type_of_referral :selected').val() == 'openMarketLegal' || $('#type_of_referral :selected').val() == 'privateSale'){
            $('.property-section').show();
        }else{
            $('.property-section').hide();
        }

        if($('#type_of_referral :selected').val() == 'legal' || $('#type_of_referral :selected').val() == 'openMarketSale' ){
            $('.purchase-legal-div').show();
            $('.sale-legal-div').show();
        }else{
            $('.purchase-legal-div').hide();
            $('.sale-legal-div').hide();
        }
    });

    $("#purchase_legal").on("click", function (e) {
        if ($(this).is(':checked')) {
            $('.purchase-property-section').show();
        }else{
            $('.purchase-property-section').hide();
        }
    });


    if($('.glyphicon-download-alt').length > 0) {
        $(".dropdown-toggle").on("click", function (e) {
            $('.dropdown-menu').hide();
            $(this).siblings('.dropdown-menu').show();
        });
    }

    $("#sale_legal").on("click", function (e) {
        if ($(this).is(':checked')) {
            $('.property-section').show();
        }else{
            $('.property-section').hide();
        }
    });
    //Save new message
    $(".submit-message").on("click", function (e) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name=_token]').val()
            }
        })

        e.preventDefault();

        var formData = {
            message: $('#new-message').val(),
            property_id: $('#property_id').val(),
            user_id: $('#user_id').val(),
        }

        var type = "POST"; //for creating new resource
        var my_url = '/messages/create';

        $.ajax({

            type: type,
            url: my_url,
            data: formData,
            dataType: 'json',
            success: function (data) {
                var new_html = '<tr><td>' + data.created_at + '</td>' +
                    '<td>' + data.message + '</td>' +
                    '<td>' + $('#user_name').val() + '</td>' +
                    '<td class="table-actions">' +
                    '<div><a href="#" id="' + data.id + '" class="delete-msg"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> Delete</a></div></td> ' +
                    '</tr>';
                $('.messages-table .top').after(new_html);

                $('#new-message').val();
            },
            error: function (data) {
            }
        });
    });


    $(".unarchive-property").on("click", function (e) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name=_token]').val()
            }
        })

        e.preventDefault();

        var formData = {
            id: $('#property_id').val(),
        }

        var type = "POST"; //for creating new resource
        var my_url = '/property/unarchive';

        $.ajax({

            type: type,
            url: my_url,
            data: formData,
            dataType: 'json',
            success: function (data) {
                window.location.href = '/properties';
            },
            error: function (data) {
            }
        });
    });
    $('#company').autocomplete({

        serviceUrl: '/search/getLettingAgents',
        type: 'GET',
        transformResult: function (response, originalQuery) {
            var suggestions = [];
            $.each(response, function (index, data) {
                suggestions.push({value: data.data,
                    data: {
                        id: data.value,
                        contact_number: data.contact_number,
                        mobile_number: data.mobile_number,
                        point_of_contact: data.point_of_contact,
                        email: data.email
                    }
                });
            });

            return {suggestions: suggestions}
        },
        dataType: 'json',
        onSelect: function (suggestion) {
            $('#lettingAgents\\[company\\]').val(suggestion.value);
            $('#letting_agent_id').val(suggestion.data.id);
            $('#lettingAgents\\[mobile_number\\]').val(suggestion.data.mobile_number);
            $('#lettingAgents\\[contact_number\\]').val(suggestion.data.contact_number);
            $('#lettingAgents\\[email\\]').val(suggestion.data.email);
            $('#lettingAgents\\[point_of_contact\\]').val(suggestion.data.point_of_contact);
        }
    });

    $('#estateAgents\\[company\\]').autocomplete({

        serviceUrl: '/search/getEstateAgents',
        type: 'GET',
        transformResult: function (response, originalQuery) {
            var suggestions = [];
            $.each(response, function (index, data) {
                suggestions.push({value: data.data,
                    data: {
                        id: data.value,
                        contact_number: data.contact_number,
                        mobile_number: data.mobile_number,
                        point_of_contact: data.point_of_contact,
                        email: data.email
                    }
                });
            });

            return {suggestions: suggestions}
        },
        dataType: 'json',
        onSelect: function (suggestion) {
            $('#estateAgents\\[company\\]').val(suggestion.value);
            $('#propertySale\\[estate_agent_id\\]').val(suggestion.data.id);
            $('#estateAgents\\[mobile_number\\]').val(suggestion.data.mobile_number);
            $('#estateAgents\\[contact_number\\]').val(suggestion.data.contact_number);
            $('#estateAgents\\[email\\]').val(suggestion.data.email);
            $('#estateAgents\\[point_of_contact\\]').val(suggestion.data.point_of_contact);
        }
    });

    $('#autocomplete_property_id').autocomplete({

        serviceUrl: '/search/getProperties',
        type: 'GET',
        transformResult: function (response, originalQuery) {
            var suggestions = [];
            $.each(response, function (index, data) {
                console.log(data);
                suggestions.push({value: data.data, id: data.value});
            });

            return {suggestions: suggestions}
        },
        dataType: 'json',
        onSelect: function (suggestion) {
            $('#autocomplete_property_id').val(suggestion.value);
            $('#property_id').val(suggestion.id);
        }
    });

    $('#factor\\[company\\]').autocomplete({

        serviceUrl: '/search/getFactors',
        type: 'GET',
        transformResult: function (response, originalQuery) {
            var suggestions = [];
            $.each(response, function (index, data) {
                suggestions.push({value: data.data,
                    data: {
                        id: data.value,
                        contact_number: data.contact_number,
                        mobile_number: data.mobile_number,
                        point_of_contact: data.point_of_contact,
                        email: data.email,
                        approx_costs: data.approx_costs,
                        monthly_charge: data.monthly_charge,
                        insurance_company_name: data.insurance_company_name
                    }
                });
            });

            return {suggestions: suggestions}
        },
        dataType: 'json',
        onSelect: function (suggestion) {
            $('#factor\\[company\\]').val(suggestion.value);
            $('#factor_id').val(suggestion.data.id);
            $('#factor\\[mobile_number\\]').val(suggestion.data.mobile_number);
            $('#factor\\[telephone\\]').val(suggestion.data.contact_number);
            $('#factor\\[email\\]').val(suggestion.data.email);
            $('#factor\\[person_in_charge\\]').val(suggestion.data.point_of_contact);
            $('#monthly_charges').val(suggestion.data.monthly_charge);
            $('#approx_costs').val(suggestion.data.approx_costs);
            $('#insurance_company_name').val(suggestion.data.insurance_company_name);
        }
    });


    //Save new key log
    $(".submit-new-key-log").on("click", function (e) {
        var error = false;
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name=_token]').val()
            }
        })

        e.preventDefault();

        var formData = {
            key_reference: $('#key_ref').val(),
            key_logger: $('#user_id').val(),
            number_of_keys_taken: $('#new_number_of_keys_taken').val(),
            reason: $('#new_reason').val(),
            key_taker: $('#new_key_taker').val(),
            key_taker_number: $('#new_key_taker_number').val(),
            key_taker_email: $('#new_key_taker_email').val(),
            date_of_return: $('#new_date_of_return').val(),
            status: 'out',
        }

        if ($('#new_key_taker').val().length == 0) {
            error = true;
            $('#new_key_taker').addClass('input-error');
        }

        if ($('#new_key_taker_number').val().length == 0) {
            error = true;
            $('#new_key_taker_number').addClass('input-error');
        }

        if ($('#new_number_of_keys_taken').val().length == 0) {
            error = true;
            $('#new_number_of_keys_taken').addClass('input-error');
        }

        var type = "POST"; //for creating new resource
        var my_url = '/keylistlog/create';

        if (!error) {
            $.ajax({

                type: type,
                url: my_url,
                data: formData,
                dataType: 'json',
                success: function (data) {
                    var new_html = '<tr class="row-' + data.id + '"><td>' + data.created_at + '</td>' +
                        '<td>' + data.number_of_keys_taken + '</td>' +
                        '<td>' + data.key_taker + '</td>' +
                        '<td>' + data.key_taker_number + '</td>' +
                        '<td>' + data.key_taker_email + '</td>' +
                        '<td>' + data.date_of_return + '</td>' +
                        '<td>' + data.reason + '</td>' +
                        '<td>' + $('#user_name').val() + '</td>' +
                        '<td class="status"></td>' +
                        '<td class="table-actions">' +
                        '<div><a href="#" id=".data.id." class="return-keys"><span class="glyphicon glyphicon-log-in" aria-hidden="true"></span>Return keys</a></div></td> ' +
                        '</tr>';

                    $('.key-log-table .top').after(new_html);

                    $('#new_number_of_keys_taken').val('');
                    $('#new_reason').val('');
                    $('#new_key_taker').val('');
                    $('#new_key_taker_number').val('');
                    $('#new_key_taker_email').val('');
                    $('#new_date_of_return').val('');
                    $('.input-error').removeClass('input-error');
                    $('.error-message').hide();
                },
                error: function (data) {
                }
            });
        } else {
            $('.error-message').show();
        }
    });
    //Save new investor property
    $(".submit-investor-property").on("click", function (e) {
        var error = false;
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name=_token]').val()
            }
        })

        e.preventDefault();

        var formData = {
            investor_id: $('#investor_id').val(),
            house_number_name: $('#new_house_number_name').val(),
            address_line: $('#new_address_line').val(),
            town: $('#new_town').val(),
            postcode: $('#new_postcode').val(),
            notes: $('#new_notes').val(),
            status: $('#new_status').val(),
        }


        var type = "POST"; //for creating new resource
        var my_url = '/investors/property/create';

        $.ajax({

            type: type,
            url: my_url,
            data: formData,
            dataType: 'json',
            success: function (data) {
                var new_html = '<tr class="row-' + data.id + '"><td>' + data.created_at + '</td>' +
                    '<td>' + data.house_number_name + ' ' + data.address_line + ' ' + data.town + '</td>' +
                    '<td>' + data.notes + '</td>' +
                    '<td>' + data.status + '</td>' +
                    '<td class="table-actions">' +
                    '<div><a href="#" id="'+data.id+'" class="edit-msg"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>Edit</a></div></td> ' +
                    '</tr>';

                $('.properties-table .top').after(new_html);

                $('#new_house_number_name').val('');
                $('#new_address_line').val('');
                $('#new_town').val('');
                $('#new_postcode').val('');
                $('#new_status').val('');
                $('#new_notes').val('');
            },
            error: function (data) {
            }
        });

    });
    //Delete fund type
    $(".remove-fund-type").on("click", function (e) {
        var id = this.id;

        e.preventDefault();
        var confirmation = confirm("Please confirm if you want to delete this fund type.");
        if (confirmation == true) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('input[name=_token]').val()
                }
            })

            var formData = {
                fund_type_id: this.id,
            }

            var type = "POST"; //for creating new resource
            var my_url = '/fund-type/delete';

            $.ajax({

                type: type,
                url: my_url,
                data: formData,
                dataType: 'json',
                success: function (data) {
                    location.reload();
                },
                error: function (data) {
                }
            });
        }
    });


    //Delete property type
    $(".remove-property-type").on("click", function (e) {
        var id = this.id;

        e.preventDefault();
        var confirmation = confirm("Please confirm if you want to delete this property type.");
        if (confirmation == true) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('input[name=_token]').val()
                }
            })

            var formData = {
                property_type_id: this.id,
            }

            var type = "POST"; //for creating new resource
            var my_url = '/property-types/delete';

            $.ajax({

                type: type,
                url: my_url,
                data: formData,
                dataType: 'json',
                success: function (data) {
                    location.reload();
                },
                error: function (data) {
                }
            });
        }
    });

    //Check keys back in
    $(".return-keys").on("click", function (e) {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name=_token]').val()
            }
        })

        e.preventDefault();
        var id = this.id;
        var formData = {
            log_id: this.id,
        }

        var type = "POST"; //for creating new resource
        var my_url = '/keylistlog/return';

        $.ajax({

            type: type,
            url: my_url,
            data: formData,
            dataType: 'json',
            success: function (data) {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth() + 1; //January is 0!
                var yyyy = today.getFullYear();
                $('#row-' + id + ' .status').html(dd + '-' + mm + '-' + yyyy);
            },
            error: function (data) {
            }
        });

    });


    //Edit investor property
    $(".edit-msg").on("click", function (e) {
        e.preventDefault();

        var id = this.id;

        var type = "POST"; //for creating new resource
        var my_url = '/investors/property/getInvestorProperty';

        var formData = {
            id: id,
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name=_token]').val()
            }
        })

        $.ajax({

            type: type,
            url: my_url,
            data: formData,
            dataType: 'json',
            success: function (data) {
                  $('#edit_investor_property_id').val(data.id);
                  $('#edit_house_number_name').val(data.house_number_name);
                  $('#edit_address_line').val(data.address_line);
                  $('#edit_town').val(data.town);
                  $('#edit_postcode').val(data.postcode);
                  $('#edit_notes').val(data.notes);
                  $('#edit_status').val(data.status);
                $('.edit-property-form').show();
            },
            error: function (data) {
            }
        });
    });


    $("#fees_expected").on("blur", function (e) {
        var fees = parseInt($('#fees_expected').val());
        var vat_total = fees*1.2;
        var vat = vat_total - fees;
        vat_total = vat_total.toFixed(2);
        vat = vat.toFixed(2);
        $('.vat-amount').html(vat);
        $('.vat-total').html(vat_total);
    });

//Delete property
    $(".remove-property").on("click", function (e) {
        var id = this.id;

        e.preventDefault();
        var confirmation = confirm("Please confirm if you want to delete this property.");
        if (confirmation == true) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('input[name=_token]').val()
                }
            })

            var formData = {
                id: this.id,
            }

            var type = "POST"; //for creating new resource
            var my_url = '/property/delete';

            $.ajax({

                type: type,
                url: my_url,
                data: formData,
                dataType: 'json',
                success: function (data) {
                    location.reload();
                },
                error: function (data) {
                }
            });
        }
    });

    $(".remove-property-file").on("click", function (e) {
        var id = this.id;

        e.preventDefault();
        var confirmation = confirm("Please confirm if you want to delete this property file.");
        if (confirmation == true) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('input[name=_token]').val()
                }
            })

            var formData = {
                id: this.id,
            }

            var type = "POST"; //for creating new resource
            var my_url = '/property-file/delete';

            $.ajax({

                type: type,
                url: my_url,
                data: formData,
                dataType: 'json',
                success: function (data) {
                    $('#file-'+id).remove();
                },
                error: function (data) {
                }
            });
        }
    });


    $("#add-new-file").on("click", function (e) {
        e.preventDefault();
        var count = parseInt($('#file-count').attr('data-id'));
        count++;

        $('.file-upload').append('<div class="form-group">' +
            '<label for="new_property_label_'+count+'" class="form-label col-md-4">New Property Form Label '+count+'</label>' +
            '<div class="col-md-6">' +
            '<input name="new_property_label_'+count+'" type="text" id="new_property_label_'+count+'">' +
            '</div>' +
            '</div>' +
            '<div class="form-group">' +
            '<label for="new_property_file_'+count+'" class="form-label col-md-4">New Property File '+count+'</label>' +
            '<div class="col-md-6">' +
            '<input name="new_property_file_'+count+'" type="file" id="new_property_file_'+count+'">' +
            '</div>' +
            '</div>');
        $('#file-count').attr('data-id',count);

    });

//Delete Key list
    $(".remove-key-list").on("click", function (e) {
        var id = this.id;

        e.preventDefault();
        var confirmation = confirm("Please confirm if you want to delete this keylist.");
        if (confirmation == true) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('input[name=_token]').val()
                }
            })

            var formData = {
                id: this.id,
            }

            var type = "POST"; //for creating new resource
            var my_url = '/key-list/delete';

            $.ajax({

                type: type,
                url: my_url,
                data: formData,
                dataType: 'json',
                success: function (data) {
                    location.reload();
                },
                error: function (data) {
                }
            });
        }
    });


    $(".submit-edit-property").on("click", function (e) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name=_token]').val()
            }
        })

        e.preventDefault();

        var formData = {
            id: $('#edit_investor_property_id').val(),
            investor_id: $('#investor_id').val(),
            house_number_name: $('#edit_house_number_name').val(),
            address_line: $('#edit_address_line').val(),
            town: $('#edit_town').val(),
            postcode: $('#edit_postcode').val(),
            notes: $('#edit_notes').val(),
            status: $('#edit_status').val(),
        }

        var id = $('#edit_investor_property_id').val();
        var type = "POST"; //for creating new resource
        var my_url = '/investors/property/update';

        $.ajax({

            type: type,
            url: my_url,
            data: formData,
            dataType: 'json',
            success: function (data) {

                $('.edit-property-form').hide();

                var new_html = '<td>' + data.created_at + '</td>' +
                    '<td>' + data.house_number_name + ' ' + data.address_line + ' ' + data.town + '</td>' +
                    '<td>' + data.notes + '</td>' +
                    '<td>' + data.status + '</td>' +
                    '<td class="table-actions">' +
                    '<div><a href="#" id="'+data.id+'" class="edit-msg"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>Edit</a></div></td> ';

                $('#row-'+id).html(new_html);

                $('#edit_investor_property_id').val('');
                $('#edit_house_number_name').val('');
                $('#edit_address_line').val('');
                $('#edit_town').val('');
                $('#edit_postcode').val('');
                $('#edit_status').val('');
                $('#edit_notes').val('');
            },
            error: function (data) {
            }
        });

    })



    $('#areas_of_interest').autocomplete({

        serviceUrl: '/search/getArea',
        type: 'GET',
        transformResult: function (response, originalQuery) {
            var suggestions = [];
            $.each(response, function (index, data) {
                suggestions.push({value: data.area,
                    id: data.id
                });
            });

            return {suggestions: suggestions}
        },
        dataType: 'json',
        onSelect: function (suggestion) {

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('input[name=_token]').val()
                }
            })

            formData = {
                investor_id: $('#investor_id').val(),
                area: suggestion.value};

            $.ajax({
                type: "POST",
                url: '/investors/add-area',
                data: formData,
                dataType: 'json',
                success: function (data) {
                    $('#areas_of_interest').val('');
                    $('.area-list').append('<li id="area-'+data.id+'">'+data.area+' <span class="glyphicon glyphicon-remove remove-area" id="'+data.id+'" aria-hidden="true"></span></li>');
                },

            });
        }
    });


    $(".remove-area").on("click", function (e) {
        var id = this.id;
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name=_token]').val()
            }
        })

        e.preventDefault();
        var my_url = '/investors/remove-area';

        $.ajax({

            type: 'POST',
            url: my_url,
            data: {id: id},
            dataType: 'json',
            success: function (data) {

                $('#area-'+id).remove();
            }
        });

    })


    $(".add-new-area").on("click", function (e) {
        var area = $('#areas_of_interest').val();
        var investor_id = $('#investor_id').val();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name=_token]').val()
            }
        })

        e.preventDefault();
        var my_url = '/investors/add-new-area';

        $.ajax({

            type: 'POST',
            url: my_url,
            data: {area: area, investor_id: investor_id},
            dataType: 'json',
            success: function (data) {
                $('#areas_of_interest').val('');
                $('.area-list').append('<li id="area-'+data.id+'">'+data.area+' <span class="glyphicon glyphicon-remove remove-area" id="'+data.id+'" aria-hidden="true"></span></li>');
            },
        });

    })


    $('.referral-company #company').autocomplete({

        serviceUrl: '/search/getReferrals',
        type: 'GET',
        transformResult: function (response, originalQuery) {
            var suggestions = [];
            $.each(response, function (index, data) {
                suggestions.push({value: data.company+', '+data.point_of_contact,
                    data: {
                        company: data.company,
                        contact_number: data.contact_number,
                        point_of_contact: data.point_of_contact,
                        email: data.email
                    }
                });
            });

            return {suggestions: suggestions}
        },
        dataType: 'json',
        onSelect: function (suggestion) {
            
            $('#company').val(suggestion.data.company);
            $('#contact_number').val(suggestion.data.contact_number);
            $('#email').val(suggestion.data.email);
            $('#point_of_contact').val(suggestion.data.point_of_contact);
        }
    });
});






